//import vue
import Vue from "vue";

//import vue router
import VueRouter from "vue-router";

//use vue router on vue
Vue.use(VueRouter);

//create object instance vue router
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/auth/Index")
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/dashboard/Index")
    },
    {
      path: "/legal-agreement",
      name: "legal-agreement",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/agreement/Index")
    },

    // ------------------------------------ Report -----------------------------
    {
      path: "/report",
      name: "report",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/report/webview-report")
    },

    {
      path: "/report-success",
      name: "report-success",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/report/webview-report-success"
        )
    },

    {
      path: "/report-list",
      name: "report-list",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/report/Index")
    },

    {
      path: "/report-detail",
      name: "report-detail",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/report/Detail")
    },

    // ------------------------------------- Halaman Home ---------------------------------------
    // Banner GET
    {
      path: "/banner",
      name: "banner",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/banner/Index"
        )
    },
    // Banner Create
    {
      path: "/banner-create",
      name: "banner-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/banner/Create"
        )
    },
    // Banner Edit
    {
      path: "/banner-edit",
      name: "banner-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/banner/Edit"
        )
    },

    // Pengumuman GET
    {
      path: "/pengumuman",
      name: "pengumuman",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/pengumuman/Index"
        )
    },
    // Pengumuman Create
    {
      path: "/pengumuman-create",
      name: "pengumuman-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/pengumuman/Create"
        )
    },
    // Pengumuman Edit
    {
      path: "/pengumuman-edit",
      name: "pengumuman-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/pengumuman/Edit"
        )
    },

    // Ucapan GET
    {
      path: "/ucapan",
      name: "ucapan",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/ucapan/Index"
        )
    },
    // Ucapan Create
    {
      path: "/ucapan-create",
      name: "ucapan-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/ucapan/Create"
        )
    },
    // ucapan Edit
    {
      path: "/ucapan-edit",
      name: "ucapan-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-home/ucapan/Edit"
        )
    },

    // ---------------------------- Halaman Divsion Start ----------------------

    // Organization Chart GET
    {
      path: "/organization-chart",
      name: "organization-chart",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/organization-chart/Index"
        )
    },
    // Organization Chart Create
    {
      path: "/organization-chart-create",
      name: "organization-chart-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/organization-chart/Create"
        )
    },
    // Organization Chart Edit
    {
      path: "/organization-chart-edit",
      name: "organization-chart-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/organization-chart/Edit"
        )
    },

    // Technical GET
    {
      path: "/technical",
      name: "technical",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/technical/Index"
        )
    },
    // Technical Create
    {
      path: "/technical-create",
      name: "technical-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/technical/Create"
        )
    },

    // Technical Edit
    {
      path: "/technical-edit",
      name: "technical-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/technical/Edit"
        )
    },

    // Industrial GET
    {
      path: "/industrial",
      name: "industrial",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/industrial/Index"
        )
    },
    // Industrial Create
    {
      path: "/industrial-create",
      name: "industrial-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/industrial/Create"
        )
    },

    // Industrial Edit
    {
      path: "/industrial-edit",
      name: "industrial-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/industrial/Edit"
        )
    },

    // Treasury GET
    {
      path: "/treasury",
      name: "treasury",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/treasury/Index"
        )
    },
    // Treasury Create
    {
      path: "/treasury-create",
      name: "treasury-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/treasury/Create"
        )
    },

    // Treasury Edit
    {
      path: "/treasury-edit",
      name: "treasury-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/treasury/Edit"
        )
    },

    // Affairs GET
    {
      path: "/affairs",
      name: "affairs",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/affairs/Index"
        )
    },
    // Affairs Create
    {
      path: "/affairs-create",
      name: "affairs-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/affairs/Create"
        )
    },

    // Affairs Edit
    {
      path: "/affairs-edit",
      name: "affairs-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/affairs/Edit"
        )
    },

    // IFALPA GET
    {
      path: "/ifalpa",
      name: "ifalpa",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/ifalpa/Index"
        )
    },
    // IFALPA Create
    {
      path: "/ifalpa-create",
      name: "ifalpa-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/ifalpa/Create"
        )
    },

    // IFALPA Edit
    {
      path: "/ifalpa-edit",
      name: "ifalpa-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-division/ifalpa/Edit"
        )
    },

    // ---------------------------- Halaman Sport ------------------------

    // Badminton GET

    {
      path: "/badminton-dashboard",
      name: "badminton-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/badminton/Index"
        )
    },

    // Badminton  Create

    {
      path: "/badminton-dashboard-create",
      name: "badminton-dashboard-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/badminton/Create"
        )
    },

    // Badminton Edit
    {
      path: "/badminton-dashboard-edit",
      name: "badminton-dashboard-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/badminton/Edit"
        )
    },

    // Basketball GET

    {
      path: "/basketball-dashboard",
      name: "basketball-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/basketball/Index"
        )
    },

    // Basketball  Create

    {
      path: "/basketball-dashboard-create",
      name: "basketball-dashboard-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/basketball/Create"
        )
    },

    // Basketball Edit
    {
      path: "/basketball-dashboard-edit",
      name: "basketball-dashboard-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/basketball/Edit"
        )
    },

    // Cycling GET

    {
      path: "/cycling-dashboard",
      name: "cycling-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/cycling/Index"
        )
    },

    // Cycling  Create

    {
      path: "/cycling-dashboard-create",
      name: "cycling-dashboard-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/cycling/Create"
        )
    },

    // Cycling Edit
    {
      path: "/cycling-dashboard-edit",
      name: "cycling-dashboard-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/cycling/Edit"
        )
    },

    // Football GET

    {
      path: "/football-dashboard",
      name: "football-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/football/Index"
        )
    },

    // Football  Create

    {
      path: "/football-dashboard-create",
      name: "football-dashboard-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/football/Create"
        )
    },

    // Football Edit
    {
      path: "/football-dashboard-edit",
      name: "football-dashboard-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/football/Edit"
        )
    },

    // Golf GET

    {
      path: "/golf-dashboard",
      name: "golf-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/golf/Index"
        )
    },

    // Golf  Create

    {
      path: "/golf-dashboard-create",
      name: "golf-dashboard-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/golf/Create"
        )
    },

    // Golf Edit
    {
      path: "/golf-dashboard-edit",
      name: "golf-dashboard-edit",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/halaman-sport/golf/Edit")
    },

    // Motorcycle GET

    {
      path: "/motorcycle-dashboard",
      name: "motorcycle-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/motorcycle/Index"
        )
    },

    // motorcycle  Create

    {
      path: "/motorcycle-dashboard-create",
      name: "motorcycle-dashboard-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/motorcycle/Create"
        )
    },

    // motorcycle Edit
    {
      path: "/motorcycle-dashboard-edit",
      name: "motorcycle-dashboard-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/motorcycle/Edit"
        )
    },

    // Shooting GET

    {
      path: "/shooting-dashboard",
      name: "shooting-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/shooting/Index"
        )
    },

    // shooting  Create

    {
      path: "/shooting-dashboard-create",
      name: "shooting-dashboard-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/shooting/Create"
        )
    },

    // shooting Edit
    {
      path: "/shooting-dashboard-edit",
      name: "shooting-dashboard-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/shooting/Edit"
        )
    },

    // Tennis GET

    {
      path: "/tennis-dashboard",
      name: "tennis-dashboard",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/tennis/Index"
        )
    },

    // Tennis  Create

    {
      path: "/tennis-dashboard-create",
      name: "tennis-dashboard-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/tennis/Create"
        )
    },

    // Tennis Edit
    {
      path: "/tennis-dashboard-edit",
      name: "tennis-dashboard-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/tennis/Edit"
        )
    },

    // Description Sport GET

    {
      path: "/desc-sport",
      name: "desc-sport",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/desc-sport/Index"
        )
    },

    // Description Sport Create

    {
      path: "/desc-sport-create",
      name: "desc-sport-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/desc-sport/Create"
        )
    },

    // Description Sport Edit
    {
      path: "/desc-sport-edit",
      name: "desc-sport-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-sport/desc-sport/Edit"
        )
    },

    // ------------------------------- Banner Event ------------------------------------------

    // Banner Event GET
    {
      path: "/banner-event",
      name: "banner-event",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-event/banner-event/Index"
        )
    },
    // Banner Event Create
    {
      path: "/banner-event-create",
      name: "banner-event-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-event/banner-event/Create"
        )
    },
    // Banner Event Edit
    {
      path: "/banner-event-edit",
      name: "banner-event-edit",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/halaman-event/banner-event/Edit"
        )
    },

    // ------------------------------- Laporan Watermark ------------------------------------------

    // Laporan Watermark GET
    {
      path: "/laporan-watermark",
      name: "laporan-watermark",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/laporan-watermark/Index")
    },
    // Laporan Watermark Create
    {
      path: "/laporan-watermark-create",
      name: "laporan-watermark-create",
      component: () =>
        import(
          /* webpackChunkName: "Auth" */ "@/views/laporan-watermark/Create"
        )
    },
    // Laporan Watermark Edit
    {
      path: "/laporan-watermark-edit",
      name: "laporan-watermark-edit",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/laporan-watermark/Edit")
    },

    // ------------------------------- List User ------------------------------------------

    // User GET
    {
      path: "/user",
      name: "user",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/list-user/Index")
    },
    // User Create
    {
      path: "/user-create",
      name: "user-create",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/list-user/Create")
    },
    // Banner Edit
    {
      path: "/user-edit",
      name: "user-edit",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/list-user/Edit")
    },

    // ------------------------------- List Update ------------------------------------------

    // User GET
    {
      path: "/update-notif",
      name: "update-notif",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/list-update/Index")
    },
    // User Create
    {
      path: "/update-notif-create",
      name: "update-notif-create",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/list-update/Create")
    },
    // Banner Edit
    {
      path: "/update-notif-edit",
      name: "update-notif-edit",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/list-update/Edit")
    },

    // --------------------- Webview ---------------------
    // Webview Football
    {
      path: "/football",
      name: "football",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/webview/Football")
    },

    // Webview Golf
    {
      path: "/golf",
      name: "golf",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/webview/Golf")
    },

    // Webview Badminton
    {
      path: "/badminton",
      name: "badminton",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/webview/Badminton")
    },

    // Webview Basketball
    {
      path: "/basketball",
      name: "basketball",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/webview/Basketball")
    },

    // Webview Cycling
    {
      path: "/cycling",
      name: "cycling",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/webview/Cycling")
    },

    // Webview Motorcycle
    {
      path: "/motorcycle",
      name: "motorcycle",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/webview/Motorcycle")
    },

    // Webview Shooting
    {
      path: "/shooting",
      name: "shooting",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/webview/Shooting")
    },

    // Webview Tennis
    {
      path: "/tennis",
      name: "tennis",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "@/views/webview/Tennis")
    }
  ]
});

export default router;
