import Vue from "vue";
import App from "./App.vue";

//import router
import router from "./router/Index";

//import bootstrap css & js
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery.min";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min";

//import axios
import axios from "axios";
//set credential axios with true
axios.defaults.withCredentials = false;
axios.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";

Vue.config.productionTip = false;

console.log(Vue.version);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
